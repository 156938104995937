import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 微信内部进件跳转的下载页面
  {
    path: "/download",
    name: "Download",
    component: () => import("../views/download"),
    meta: {
      title: "领取额度",
    },
  },
  // 协议页面
  {
    path: "/protocol",
    name: "Protocol",
    component: () => import("../views/protocol"),
    meta: {
      title: "协议",
    },
  },
  // 404页面
  {
    path: "/404",
    name: "NoPage",
    component: () => import("../views/404"),
    meta: {
      title: "404",
    },
  },
  // 产品推荐结果页面
  {
    path: "/productRecomed",
    name: "ProductRecomed",
    component: () => import("../views/productRecomed"),
    meta: {
      title: "产品推荐",
    },
  },
  // 了解融树客介绍页面
  {
    path: "/know",
    name: "Know",
    component: () => import("../views/know"),
    meta: {
      title: "了解融树客",
    },
  },
  // 默认进件页面
  {
    path: "/registerEntry_tl1",
    name: "RegisterEntryTl1",
    component: () => import("../views/registerEntry_tl1"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 宜享花专用进件页面
  {
    path: "/registerEntry_tl2",
    name: "RegisterEntryTl2",
    component: () => import("../views/registerEntry_tl2"),
    meta: {
      title: "额度确认",
    },
  },
  // 通用进件页面(带弹窗)
  {
    path: "/registerEntry_tl3",
    name: "RegisterEntryTl3",
    component: () => import("../views/registerEntry_tl3"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 默认进件页面(第二套)
  {
    path: "/registerEntry_tl4",
    name: "RegisterEntryTl4",
    component: () => import("../views/registerEntry_tl4"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 三方签约结果
  {
    path: "/triContract",
    name: "TriContract",
    component: () => import("../views/triContract"),
    meta: {
      title: "三方签约结果",
    },
  },
  // 此路由为不同项目域名的演示流程进件页面（不调接口）
  {
    path: "/interProRegisterEntry",
    name: "InterProRegisterEntry",
    component: () => import("../views/interProRegisterEntry"),
    meta: {
      title: "三分钟最高可借20万",
    },
  },
  // 此路由为不同项目域名的演示流程完成（不调接口）
  {
    path: "/interProPassedSuccess",
    name: "InterProPassedSuccess",
    component: () => import("../views/interProPassedSuccess"),
    meta: {
      title: "三分钟最高可借20万",
    },
  },
  // 此路由为贷超产品跳转中间页面路由
  {
    path: "/convenientEntrance",
    name: "ConvenientEntrance",
    component: () => import("../views/convenientEntrance"),
    meta: {
      title: "产品推荐",
    },
  },
  // 活动
  {
    path: "/activity",
    name: "Activity",
    meta: {
      title: "",
    },
    component: () => import("../views/activity"),
    children: [
      // 转盘活动页面
      {
        path: "turntable",
        name: "Turntable",
        component: () => import("../views/activity/turntable"),
        meta: {
          title: "幸运大转盘",
        },
      },
      {
        path: "turntable/rule",
        name: "TurntableRule",
        component: () => import("../views/activity/turntable/rule"),
        meta: {
          title: "活动规则",
        },
      },
      {
        path: "turntable/prize",
        name: "TurntablePrize",
        component: () => import("../views/activity/turntable/prize"),
        meta: {
          title: "我的奖品",
        },
      },
    ],
  },
  // H5唤醒APP,
  {
    path: "/openApp",
    name: "OpenApp",
    component: () => import("../views/liftingAPP/openApp"),
    meta: {
      title: "打开融树客",
    },
  },
  // H5下载页，点击按钮跳应用市场,
  {
    path: "/downloadApp",
    name: "DownloadApp",
    component: () => import("../views/liftingAPP/downloadApp"),
    meta: {
      title: "下载融树客",
    },
  },
  // 进件结果页面(引导接听电话下载页)
  {
    path: "/trialSuccess_tl1",
    name: "TrialSuccessTl1",
    component: () => import("../views/trialSuccess_tl1"),
    meta: {
      title: "",
    },
  },
  // 进件结果页面(红包下载页面)
  {
    path: "/trialSuccess_tl2",
    name: "TrialSuccessTl2",
    component: () => import("../views/trialSuccess_tl2"),
    meta: {
      title: "确认额度",
    },
  },
  // 进件结果页面(引导接听电话下载页-带外呼号码)
  {
    path: "/trialSuccess_tl3",
    name: "TrialSuccessTl3",
    component: () => import("../views/trialSuccess_tl3"),
    meta: {
      title: "等待电话审核",
    },
  },
  // 进件结果页面(进件失败是否引导跳转企微-后台着陆页配置中对应的[进件失败是否引导企微]开关)
  {
    path: "/trialSuccess_tl4",
    name: "TrialSuccessTl4",
    component: () => import("../views/trialSuccess_tl4"),
    meta: {
      title: "贷款申请已提交",
    },
  },
  // 进件结果页面(被兜底机构接收：跳转新增的引导下载页面, 无法被接收：跳转新增的引导下载页面，并在页面加载完成后5s自动跳转应用市场)
  {
    path: "/trialSuccess_tl5",
    name: "TrialSuccessTl5",
    component: () => import("../views/trialSuccess_tl5"),
    meta: {
      title: "贷款申请已提交",
    },
  },
  {
    path: "/trialSuccess_tl7",
    name: "TrialSuccessTl7",
    component: () => import("../views/trialSuccess_tl7"),
    meta: {
      title: "贷款申请已提交",
    },
  },
  // H5还款计划
  {
    path: "/repaymentPlan",
    name: "RepaymentPlan",
    component: () => import("../views/repaymentPlan"),
    meta: {
      title: "还款计划",
    },
  },
  // 会员权益页面
  {
    path: "/member",
    name: "Member",
    component: () => import("../views/member"),
    meta: {
      title: "会员",
    },
  },
  //激活额度跳转页面
  {
    path: "/activeLimitDefauleJump",
    name: "activeLimitDefauleJump",
    component: () => import("../views/activeLimitDefauleJump"),
    meta: {
      title: "审批放款",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  var timestamp = new Date().getTime();
  to.query.tsp = timestamp;
  next();
});
export default router;
